import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/code2.png' 
import HeaderSocials from './HeaderSocials'
import Fade from 'react-reveal/Fade';

const Header = () => {
  return (
    <header>
      <div className='container header__container'>
        <Fade delay={200}>
        <h5>Hello I'm</h5>
        </Fade>
        <Fade delay={1000} duration={1800}>
        <h1 className='my__name'>Amr Mohamed</h1>
        </Fade>
        <Fade delay={200}>
        <h5 className='text-light'>Fullstack Developer</h5>
        </Fade>
        <CTA />
        <HeaderSocials />
        <Fade bottom delay={500} duration={1200}>
        <div className='me'>
          <img className='mee' src={ME} alt="me"/>
        </div>
        </Fade>
        <a href="#contact" className='scroll__down'>Scroll Down</a>


      </div>
    </header>
  )
}

export default Header