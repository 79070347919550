import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/port1.PNG'
import IMG2 from '../../assets/port2.PNG'
import IMG3 from '../../assets/port3.PNG'
import IMG4 from '../../assets/port4.PNG'
import IMG5 from '../../assets/port5.PNG'
import IMG6 from '../../assets/port6.PNG'



const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG1} alt=""/>
          </div>
          <h3>Sortilizer</h3>
          <div className='portfolio__item-cta'>
          <a href='https://github.com/amrhisham9/sorting-visualizer-react' className='btn' target='_blank'>Github</a>
          <a href='https://sortilizer.netlify.app/' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG2} alt=""/>
          </div>
          <h3>SayItLoud</h3>
          <div className='portfolio__item-cta'>
          <a href='https://github.com/amrhisham9/say-it-loud' className='btn' target='_blank'>Github</a>
          <a href='https://sayittloud.herokuapp.com/' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG3} alt=""/>
          </div>
          <h3>Orderino</h3>
          <div className='portfolio__item-cta'>
          <a href='https://github.com/amrhisham9/online-store' className='btn' target='_blank'>Github</a>
          <a href='https://orderino.netlify.app/' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG4} alt=""/>
          </div>
          <h3>Chatty</h3>
          <div className='portfolio__item-cta'>
          <a href='https://github.com/amrhisham9/' className='btn' target='_blank'>Github</a>
          <a href='https://chatt-y.netlify.app/' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG5} alt=""/>
          </div>
          <h3>EasyWord</h3>
          <div className='portfolio__item-cta'>
          <a href='https://github.com/amrhisham9/Dictionary---Website' className='btn' target='_blank'>Github</a>
          <a href='https://github.com' className='btn btn-primary dis' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG6} alt=""/>
          </div>
          <h3>Shortly</h3>
          <div className='portfolio__item-cta'>
          <a href='https://github.com/amrhisham9/Shortly-Website' className='btn' target='_blank'>Github</a>
          <a href='https://github.com' className='btn btn-primary dis' target='_blank'>Live Demo</a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio