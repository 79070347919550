import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'
const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>Fast Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Speedy Development Process</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Secure & Scalable Code</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className='service__head'>
            <h3>Modern Practices</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Reactive & Responsive Features</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Realtime & Interactive Programming Interface</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cross Platform & Cross Browser Compatibility</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className='service__head'>
            <h3>Quality Code</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Reliable Coding Enviroment</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Robust Code</p>
            </li>
          </ul>
        </article>

      </div>
    </section>
  )
}

export default Services