import React from 'react'
import './about.css'
import ME from '../../assets/amoor2.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const about = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='About Img' />
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>10+ Worldwide</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>30+ Completed Projects</small>
            </article>
          </div>
          <p>
          I'm a Software Engineer focused in Fullstack Web Development. My
          expertise includes working with Typescript, Angular, React, and Node.
          Additionally, I have experience with SQL/NoSQL databases, Docker,
          RabbitMQ, Kubernetes (K8s), and AWS, which enables me to build and
          maintain scalable, reliable applications. I'm based in Berlin, Germany, but I'm also happy working
          remotely and have experience in remote
          teams. When I'm not coding, you'll find me
          outdoors. I love being out in nature whether
          that's going for a walk, run or cycling.
          </p>
          <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default about