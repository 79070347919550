import React from 'react'
import CV from '../../assets/AmrMohamedCV.pdf'
import Fade from 'react-reveal/Fade';

const CTA = () => {
  return (
    <div className='cta'>
    <Fade left delay={300} duration={1800}>
        <a href={CV} download className='btn'>Download CV</a>
    </Fade>
    <Fade right delay={300} duration={1800}>
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
    </Fade>    
    </div>
  )
}

export default CTA